import { IsBlizko, IsFoodSpot } from 'core/availableFunctional';

import { BlizkoPreloader } from './Preloaders/Blizko/Preloader.icon';
import { FoodSpotPreloader } from './Preloaders/FoodSpot/Preloader.icon';
import { IzipointPreloader } from './Preloaders/Izipoint/Preloader.icon';
import React from 'react';

export const PreloaderIcon = () => {
  if (IsBlizko) {
    return <BlizkoPreloader />;
  }
  else if (IsFoodSpot) {
    return <FoodSpotPreloader />
  }
  else {
    return <IzipointPreloader />;
  }
};

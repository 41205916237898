import { Colors } from "app/views/pages/WebPurchase/Themes/GlobalTheme/GeneralTheme"
import cn from 'classnames';
import css from '../../../Preloader/styles.module.scss';
import React from 'react';

export const BlizkoPreloader = () => {
    return (
      <div className={cn(css['svgContainer'])}>
        <svg width="220" height="100" viewBox="0 0 140 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx='-30' cy='92' r='5' fill={Colors.imgTitle} id={cn(css['circle-1'])} />
        <circle cx='-20' cy='92' r='5' fill={Colors.imgTitle} id={cn(css['circle-2'])} />
        <circle cx='-10' cy='92' r='5' fill={Colors.imgTitle} id={cn(css['circle-3'])} />

        <path d="M0.644531 74.5171V62.9697H2.50908L6.76588 71.4413L11.0227 62.9697H12.9048V74.5171H10.7061V67.4867L7.18806 74.5171H6.16783L2.6498 67.4867V74.5171H0.644531Z" fill="#FF6633"/>
        <path d="M26.2381 62.9697V74.5171H24.0745V66.4849L18.3049 74.5171H16.5459V62.9697H18.6919V70.8437L24.4791 62.9697H26.2381Z" fill="#FF6633"/>
        <path d="M29.8086 74.5171V62.9697H32.0777V68.5237L36.5984 62.9697H39.448L34.6635 68.7522L39.9405 74.5171H36.915L32.0953 68.9631V74.5171H29.8086Z" fill="#FF6633"/>
        <path d="M41.6113 79.6499V62.9704H43.599V71.2662H43.8629V79.6324L41.6113 79.6499ZM47.0467 74.7814C46.0572 74.8184 45.0823 74.5348 44.2674 73.9729C43.4823 73.432 42.8712 72.6748 42.5084 71.7935C42.1136 70.8048 41.9222 69.7468 41.9455 68.6826C41.9222 67.6184 42.1136 66.5604 42.5084 65.5717C42.8805 64.6959 43.4896 63.9411 44.2674 63.3923C45.0817 62.8447 46.0481 62.5679 47.029 62.6013C48.0197 62.5778 48.9945 62.8535 49.8259 63.3923C50.5934 63.952 51.2 64.7036 51.5849 65.5717C52.0029 66.5556 52.2183 67.6136 52.2183 68.6826C52.2183 69.7515 52.0029 70.8095 51.5849 71.7935C51.2085 72.6715 50.6006 73.4309 49.8259 73.9905C49.0022 74.532 48.0322 74.808 47.0467 74.7814ZM46.73 72.7602C47.3613 72.7836 47.9817 72.5914 48.489 72.2153C48.9665 71.8335 49.3313 71.3293 49.5444 70.7565C49.7775 70.0904 49.8907 69.3882 49.8787 68.6826C49.8909 67.9827 49.7777 67.2863 49.5444 66.6262C49.3204 66.0547 48.9504 65.5517 48.4714 65.1674C47.9599 64.7998 47.3424 64.6086 46.7124 64.6226C46.0858 64.5931 45.4671 64.7724 44.9534 65.1323C44.4816 65.5008 44.1321 66.0031 43.9508 66.5735C43.715 67.2511 43.6019 67.9653 43.6166 68.6826C43.6036 69.3984 43.7106 70.1112 43.9332 70.7917C44.1232 71.3623 44.4782 71.8639 44.9534 72.2329C45.4666 72.6144 46.0904 72.818 46.73 72.8129V72.7602Z" fill="#FF6633"/>
        <path d="M60.2391 74.7806C59.1842 74.8051 58.1432 74.5375 57.2312 74.0073C56.3901 73.4841 55.7135 72.7349 55.2787 71.8454C54.8068 70.8586 54.5719 69.7753 54.5926 68.6818C54.5672 67.5802 54.8086 66.4888 55.2963 65.5006C55.734 64.6167 56.4182 63.8782 57.2664 63.3739C58.1671 62.8474 59.1958 62.5798 60.2391 62.6005C61.2966 62.5728 62.3401 62.8472 63.247 63.3915C64.106 63.919 64.7958 64.6814 65.2347 65.5884C65.7082 66.5685 65.9433 67.6465 65.9207 68.7345C65.9483 69.8363 65.7068 70.9282 65.2171 71.9157C64.7796 72.8044 64.0958 73.5486 63.247 74.06C62.3304 74.5735 61.2891 74.823 60.2391 74.7806ZM60.2391 72.6715C60.7117 72.7046 61.1852 72.6209 61.6178 72.4278C62.0504 72.2347 62.4287 71.9381 62.7193 71.5642C63.2909 70.7148 63.5745 69.7044 63.5284 68.6818C63.5852 67.6554 63.2939 66.6399 62.7017 65.7993C62.4099 65.4324 62.0334 65.1416 61.6045 64.9518C61.1757 64.762 60.7071 64.6789 60.2391 64.7096C59.6152 64.7016 59.0029 64.879 58.4801 65.2193C57.9886 65.5658 57.6094 66.0485 57.3895 66.6078C57.1459 67.2717 57.0267 67.9748 57.0377 68.6818C56.9834 69.716 57.281 70.7381 57.882 71.5818C58.1548 71.9467 58.5112 72.2409 58.9214 72.4397C59.3315 72.6385 59.7834 72.7361 60.2391 72.7242V72.6715Z" fill="#FF6633"/>
        <path d="M68.8408 74.5171V62.9697H70.7229L74.9797 71.4413L79.3069 62.9697H81.0659V74.5171H78.9551V67.4867L75.4371 74.5171H74.4169L70.8989 67.4867V74.5171H68.8408Z" fill="#FF6633"/>
        <path d="M87.9786 74.7799C87.2344 74.8074 86.4957 74.644 85.8326 74.3054C85.3049 74.0021 84.8724 73.5578 84.5837 73.0224C84.2787 72.4878 84.1265 71.8798 84.1439 71.2648C84.1357 70.7398 84.2376 70.2189 84.4429 69.7357C84.6526 69.2874 84.9735 68.9002 85.3752 68.6108C85.825 68.2727 86.3313 68.0168 86.8704 67.8551C87.4487 67.6875 88.036 67.5525 88.6294 67.4508L90.6699 67.1344L92.7983 66.8357L91.9891 67.2751C92.0578 66.5604 91.857 65.8459 91.4262 65.2714C91.1514 65.0243 90.8279 64.8373 90.4766 64.7223C90.1252 64.6073 89.7537 64.5668 89.3858 64.6035C88.7721 64.5835 88.1644 64.7292 87.6268 65.0253C87.1027 65.3362 86.7232 65.8417 86.5714 66.4314L84.5837 65.8866C84.8392 64.9404 85.41 64.1096 86.202 63.5314C87.1467 62.9019 88.2694 62.5938 89.4034 62.6526C90.3338 62.6318 91.2577 62.8116 92.1122 63.1799C92.902 63.5193 93.5316 64.1483 93.8713 64.9375C94.0548 65.3133 94.1737 65.7173 94.2231 66.1326C94.2231 66.5544 94.2231 67.0114 94.2231 67.5035V74.5339H92.2354V71.8975L92.6224 72.2314C92.2294 73.0374 91.6205 73.7188 90.8634 74.1999C89.9763 74.6627 88.9757 74.8638 87.9786 74.7799ZM88.3655 73.0224C88.9294 73.0398 89.4896 72.9254 90.0014 72.6884C90.4294 72.4858 90.8077 72.1918 91.1096 71.8272C91.3735 71.5111 91.5602 71.1381 91.6549 70.7375C91.7769 70.362 91.8479 69.9719 91.866 69.5775C91.866 69.1557 91.866 68.8217 91.866 68.5581L92.5872 68.8217L90.6699 69.1205L89.1043 69.3666C88.6817 69.4374 88.2644 69.5373 87.8554 69.6654C87.5703 69.7592 87.2984 69.8892 87.0463 70.052C86.8095 70.1813 86.6097 70.3688 86.4658 70.5969C86.334 70.833 86.2673 71.0999 86.2723 71.3702C86.259 71.6706 86.3378 71.9678 86.4981 72.2222C86.6584 72.4766 86.8926 72.6761 87.1694 72.7939C87.5444 72.9634 87.9544 73.0417 88.3655 73.0224Z" fill="#FF6633"/>
        <path d="M97.4775 79.6499V62.9704H99.4652V71.2662H99.7291V79.6323L97.4775 79.6499ZM102.913 74.7814C101.923 74.821 100.947 74.5371 100.134 73.9729C99.3485 73.4319 98.7374 72.6748 98.3746 71.7935C97.6004 69.7917 97.6004 67.5735 98.3746 65.5716C98.7347 64.6888 99.3464 63.9309 100.134 63.3922C100.941 62.8449 101.902 62.568 102.878 62.6013C103.874 62.5754 104.855 62.8511 105.692 63.3922C106.46 63.952 107.066 64.7036 107.451 65.5716C107.869 66.5556 108.085 67.6136 108.085 68.6826C108.085 69.7515 107.869 70.8095 107.451 71.7935C107.075 72.6715 106.467 73.4309 105.692 73.9905C104.868 74.5319 103.898 74.808 102.913 74.7814ZM102.596 72.7602C103.228 72.7836 103.848 72.5914 104.355 72.2153C104.832 71.837 105.192 71.3311 105.393 70.7565C105.632 70.0914 105.751 69.3892 105.745 68.6826C105.751 67.9816 105.632 67.2852 105.393 66.6262C105.183 66.0517 104.818 65.5467 104.338 65.1674C103.826 64.7998 103.209 64.6086 102.579 64.6225C101.952 64.589 101.332 64.7686 100.82 65.1323C100.348 65.5008 99.9983 66.0031 99.817 66.5735C99.5813 67.2511 99.4681 67.9653 99.4828 68.6826C99.4698 69.3983 99.5768 70.1112 99.7994 70.7917C99.9824 71.3658 100.339 71.8693 100.82 72.2329C101.33 72.6191 101.956 72.8233 102.596 72.8129V72.7602Z" fill="#FF6633"/>
        <path d="M111.075 74.5171V62.9697H113.344V68.5237L117.865 62.9697H120.715L115.93 68.7522L121.207 74.5171H118.182L113.362 68.9631V74.5171H111.075Z" fill="#FF6633"/>
        <path d="M127.591 74.7814C126.532 74.8099 125.486 74.5486 124.565 74.0256C123.704 73.5371 123.002 72.811 122.542 71.9341C122.049 70.9598 121.801 69.8798 121.821 68.788C121.789 67.6576 122.031 66.5362 122.525 65.5189C122.969 64.6248 123.658 63.8751 124.512 63.3571C125.412 62.8448 126.433 62.5838 127.468 62.6013C128.531 62.5675 129.579 62.8554 130.476 63.4274C131.318 63.9942 131.968 64.8032 132.34 65.7474C132.755 66.8701 132.917 68.0702 132.815 69.2626H130.581V68.4365C130.646 67.3917 130.381 66.353 129.825 65.4662C129.549 65.1392 129.2 64.8825 128.805 64.7174C128.41 64.5524 127.982 64.4837 127.556 64.5171C127.083 64.4852 126.61 64.5639 126.173 64.747C125.736 64.93 125.348 65.2124 125.04 65.5716C124.42 66.4659 124.122 67.5443 124.196 68.6298C124.129 69.6763 124.428 70.7136 125.04 71.565C125.335 71.9162 125.708 72.1931 126.13 72.3732C126.552 72.5534 127.01 72.6318 127.468 72.602C128.085 72.6009 128.692 72.4372 129.227 72.1274C129.742 71.7994 130.151 71.3294 130.405 70.7741L132.604 71.4595C132.186 72.4515 131.474 73.2917 130.563 73.8674C129.677 74.4416 128.647 74.758 127.591 74.7814ZM123.475 69.3329V67.5753H131.707V69.3329H123.475Z" fill="#FF6633"/>
        <path d="M138.797 74.5171V64.9909H134.856V62.9697H144.971V64.9909H141.031V74.5171H138.797Z" fill="#FF6633"/>
        <path d="M147.592 74.5171V62.9697H149.843V66.977H151.426H152.482C152.808 66.9704 153.133 67.0058 153.449 67.0825C153.984 67.2134 154.49 67.4397 154.944 67.7504C155.397 68.0466 155.762 68.4595 156 68.9455C156.252 69.4967 156.378 70.0971 156.369 70.7031C156.41 71.5702 156.128 72.4216 155.578 73.0934C155.028 73.7168 154.285 74.1377 153.467 74.2886C153.137 74.3615 152.802 74.4027 152.464 74.4116H151.391L147.592 74.5171ZM149.843 72.7595H152.183C152.397 72.7444 152.609 72.7091 152.816 72.654C153.169 72.5417 153.481 72.3277 153.713 72.0389C154.002 71.7023 154.153 71.2691 154.135 70.8261C154.156 70.3696 154.012 69.9208 153.731 69.5607C153.473 69.2433 153.112 69.0257 152.71 68.9455C152.518 68.9174 152.322 68.9174 152.13 68.9455H151.567H149.808L149.843 72.7595ZM157.688 74.5171V62.9697H159.922V74.5171H157.688Z" fill="#FF6633"/>
        <path d="M5.42849 95.1522C4.44813 95.1693 3.48151 94.9203 2.63167 94.4316C1.86586 93.93 1.25639 93.2236 0.872667 92.3928C0.418649 91.3791 0.167656 90.2865 0.133874 89.1764C0.0986044 88.509 0.0986044 87.8402 0.133874 87.1728C0.169496 86.4535 0.251754 85.7372 0.380134 85.0285C0.459248 84.426 0.612873 83.8357 0.83748 83.2709C1.02841 82.7483 1.3147 82.2656 1.68181 81.8473C2.01514 81.4309 2.40603 81.064 2.84275 80.7576C3.31321 80.441 3.82926 80.198 4.37309 80.037C4.94913 79.8565 5.53682 79.7156 6.13208 79.6152L7.89109 79.3515C8.52433 79.3515 9.12239 79.1933 9.6501 79.0703L9.86119 81.074C9.47421 81.074 9.01686 81.2321 8.47156 81.3024L6.8357 81.4958C6.28981 81.5565 5.74898 81.6564 5.2174 81.7946C4.74357 81.8975 4.29124 82.0818 3.88057 82.3394C3.32658 82.6681 2.89444 83.1677 2.64926 83.7631C2.36852 84.4101 2.19618 85.0989 2.13914 85.8019C2.56526 85.154 3.17885 84.6513 3.89815 84.3606C4.56942 84.0682 5.29425 83.9186 6.02655 83.9212C6.94049 83.8887 7.84371 84.1266 8.62289 84.605C9.40207 85.0834 10.0225 85.781 10.4065 86.6104C10.8103 87.4986 11.0144 88.4645 11.0045 89.4401C11.0327 90.4801 10.7903 91.5096 10.3009 92.428C9.85418 93.2634 9.17642 93.9528 8.34845 94.414C7.45527 94.9079 6.44928 95.1623 5.42849 95.1522ZM5.55163 93.0431C5.98547 93.0724 6.42025 93.0021 6.82271 92.8376C7.22517 92.6731 7.58461 92.4187 7.87351 92.094C8.43042 91.3714 8.71108 90.474 8.66506 89.5631C8.71458 88.6353 8.42026 87.7217 7.83832 86.997C7.21785 86.4111 6.39653 86.0847 5.54283 86.0847C4.68913 86.0847 3.8678 86.4111 3.24732 86.997C2.7308 87.7533 2.45449 88.6475 2.45449 89.5631C2.45449 90.4787 2.7308 91.3729 3.24732 92.1292C3.53689 92.4364 3.88972 92.6773 4.28147 92.8351C4.67322 92.9928 5.09457 93.0638 5.51645 93.0431H5.55163Z" fill="#FF6633"/>
        <path d="M19.0613 95.1346C18.003 95.1605 16.9575 94.8993 16.0358 94.3788C15.1762 93.8802 14.475 93.1491 14.013 92.2697C13.5175 91.3025 13.2697 90.2276 13.2918 89.1412C13.2666 88.0039 13.5138 86.877 14.013 85.8545C14.4505 84.9659 15.1343 84.2216 15.9831 83.7103C16.8862 83.1916 17.9144 82.9302 18.9558 82.9545C20.0138 82.9164 21.0579 83.2049 21.9461 83.7806C22.7972 84.3379 23.4498 85.15 23.8107 86.1006C24.2246 87.2236 24.3925 88.4223 24.3032 89.6158H22.0693V88.8424C22.1287 87.7958 21.858 86.7569 21.2953 85.8721C21.0234 85.5444 20.6774 85.2859 20.286 85.1178C19.8946 84.9498 19.4688 84.877 19.0438 84.9054C18.5671 84.8742 18.0898 84.955 17.6499 85.1411C17.2101 85.3273 16.82 85.6137 16.5108 85.9776C15.893 86.8664 15.5954 87.9384 15.6665 89.0182C15.6 90.0701 15.8982 91.1128 16.5108 91.9709C16.81 92.3213 17.1866 92.5975 17.6109 92.7774C18.0353 92.9574 18.4957 93.0363 18.9558 93.0079C19.5745 93.0128 20.1827 92.8487 20.7148 92.5333C21.2301 92.2053 21.6394 91.7353 21.8934 91.18L24.0921 91.8655C23.6798 92.861 22.9664 93.7029 22.0517 94.2734C21.1542 94.8321 20.1188 95.1302 19.0613 95.1346ZM14.9453 89.6685V87.9109H23.1774V89.6685H14.9453Z" fill="#FF6633"/>
        <path d="M30.9859 95.1356C29.9332 95.1497 28.8935 94.9021 27.9604 94.415C27.0965 93.9613 26.3727 93.2807 25.8672 92.4465L27.6262 91.2865C28.0089 91.813 28.5009 92.2507 29.0686 92.5696C29.6048 92.8732 30.2114 93.0308 30.8276 93.0265C31.4508 93.0717 32.0709 92.9044 32.5866 92.552C32.7873 92.3975 32.948 92.1972 33.0551 91.9678C33.1623 91.7384 33.2127 91.4867 33.2023 91.2338C33.2194 91.0567 33.1967 90.878 33.1358 90.7107C33.0749 90.5435 32.9775 90.3919 32.8505 90.2671C32.5718 90.0339 32.2277 89.8927 31.8654 89.8629C31.4 89.7895 30.9294 89.7543 30.4582 89.7574H29.4204V87.9999H30.4582C31.0593 88.0288 31.6595 87.9269 32.2172 87.7011C32.4307 87.6097 32.6101 87.4537 32.73 87.255C32.85 87.0564 32.9044 86.8251 32.8857 86.5938C32.9046 86.3658 32.8605 86.1371 32.7581 85.9325C32.6557 85.7279 32.4991 85.5554 32.3052 85.4338C31.8826 85.1924 31.4022 85.0709 30.9156 85.0823C30.3076 85.0775 29.7064 85.2096 29.1566 85.4689C28.6219 85.697 28.1286 86.0117 27.6966 86.4005L26.3773 84.8714C26.9875 84.2803 27.7036 83.8093 28.4881 83.4829C29.2906 83.1497 30.1522 82.9823 31.0211 82.9907C31.7261 82.981 32.4259 83.1124 33.0792 83.3774C33.6807 83.6176 34.2042 84.019 34.5919 84.5374C34.9911 85.0748 35.1958 85.7317 35.1724 86.4005C35.1821 86.9523 35.0488 87.4972 34.7854 87.9823C34.4917 88.4961 34.0895 88.9399 33.6069 89.2829V88.5271C34.0163 88.6557 34.3898 88.8784 34.6975 89.1774C34.9866 89.479 35.2084 89.8384 35.3483 90.232C35.4893 90.639 35.5607 91.0668 35.5594 91.4974C35.5852 92.2155 35.3683 92.9212 34.9437 93.5011C34.5193 94.0551 33.9546 94.4859 33.3079 94.749C32.5664 95.0276 31.7777 95.159 30.9859 95.1356Z" fill="#FF6633"/>
        <path d="M43.3516 94.8159V83.2686H53.0789V94.8159H50.845V85.3249H45.5679V94.8159H43.3516Z" fill="#FF6633"/>
        <path d="M56.668 99.9321V83.2702H58.6556V91.566H58.9195V99.9321H56.668ZM62.1033 95.1339C61.1136 95.1735 60.1379 94.8897 59.3241 94.3254C58.543 93.7801 57.933 93.0243 57.5651 92.146C57.1675 91.1577 56.9702 90.1002 56.9846 89.0351C56.9664 87.9697 57.1638 86.9115 57.5651 85.9242C57.9313 85.0449 58.5417 84.2887 59.3241 83.7448C60.1319 83.1975 61.0927 82.9205 62.0681 82.9539C63.0646 82.9279 64.0457 83.2036 64.8826 83.7448C65.65 84.3046 66.2567 85.0562 66.6416 85.9242C67.0596 86.9082 67.275 87.9662 67.275 89.0351C67.275 90.1041 67.0596 91.1621 66.6416 92.146C66.2679 93.0209 65.6591 93.7752 64.8826 94.3254C64.0639 94.8795 63.0917 95.1623 62.1033 95.1339ZM61.7163 93.1127C62.3476 93.1361 62.968 92.9439 63.4754 92.5679C63.953 92.1827 64.3127 91.6711 64.5132 91.0915C64.9826 89.7607 64.9826 88.3095 64.5132 86.9787C64.3084 86.4016 63.9421 85.8952 63.4578 85.5199C62.9462 85.1523 62.3287 84.9611 61.6988 84.9751C61.0716 84.9415 60.4516 85.1212 59.9397 85.4848C59.4679 85.8533 59.1184 86.3557 58.9371 86.926C58.6983 87.6029 58.5851 88.3176 58.6029 89.0351C58.5912 89.7508 58.6982 90.4635 58.9195 91.1442C59.1075 91.7158 59.4629 92.2179 59.9397 92.5854C60.4572 92.9529 61.082 93.1383 61.7163 93.1127Z" fill="#FF6633"/>
        <path d="M75.2961 95.1326C74.2413 95.1571 73.2002 94.8894 72.2882 94.3592C71.4467 93.8314 70.7655 93.0839 70.3181 92.1974C69.8602 91.2024 69.623 90.1202 69.623 89.025C69.623 87.9298 69.8602 86.8475 70.3181 85.8525C70.7536 84.9727 71.4309 84.2351 72.2706 83.7258C73.1706 83.1977 74.1999 82.9299 75.2434 82.9525C76.298 82.9298 77.3386 83.1973 78.2513 83.7258C79.0923 84.249 79.769 84.9982 80.2038 85.8877C80.6933 86.8627 80.9349 87.9432 80.9074 89.0338C80.9329 90.1353 80.6915 91.2267 80.2038 92.215C79.7663 93.1036 79.0825 93.8479 78.2337 94.3592C77.3429 94.8786 76.3274 95.146 75.2961 95.1326ZM75.2961 93.0235C75.7664 93.0584 76.238 92.9754 76.668 92.782C77.0981 92.5886 77.473 92.291 77.7588 91.9162C78.2983 91.0514 78.5843 90.0528 78.5843 89.0338C78.5843 88.0147 78.2983 87.0161 77.7588 86.1513C77.468 85.7832 77.0917 85.4916 76.6625 85.3017C76.2334 85.1118 75.7644 85.0294 75.2961 85.0616C74.6415 85.0271 73.9933 85.206 73.4492 85.5713C72.954 85.9138 72.5739 86.3978 72.3586 86.9598C72.115 87.6237 71.9958 88.3267 72.0068 89.0338C71.9526 90.0679 72.2501 91.09 72.8511 91.9338C73.1382 92.3016 73.5113 92.5934 73.9376 92.7834C74.3639 92.9734 74.8304 93.0558 75.2961 93.0235Z" fill="#FF6633"/>
        <path d="M83.0537 97.382V93.0232C83.3197 93.0471 83.5872 92.9994 83.8285 92.885C84.0698 92.7706 84.276 92.5938 84.4257 92.3728C84.7758 91.6991 84.9966 90.9659 85.0766 90.211C85.1821 89.5959 85.2525 88.9456 85.3228 88.2425C85.3932 87.5395 85.446 86.7837 85.4987 85.9752C85.5515 85.1667 85.6043 84.2177 85.657 83.2686H93.9244V93.0232H95.1381V97.382H93.0097V94.8159H85.2525V97.382H83.0537ZM86.3431 93.0232H91.6201V85.0613H87.5744C87.5744 85.5358 87.5744 86.028 87.4864 86.5201L87.3809 88.014C87.3809 88.4886 87.3809 88.9631 87.2401 89.4201C87.0994 89.8771 87.1346 90.2637 87.0643 90.6328C87.0015 91.1086 86.9016 91.5788 86.7652 92.0389C86.6964 92.3933 86.5524 92.7289 86.3431 93.0232Z" fill="#FF6633"/>
        <path d="M100.414 95.1325C99.6703 95.1583 98.9319 94.9951 98.2683 94.6579C97.7182 94.3747 97.265 93.9342 96.9666 93.3925C96.658 92.8587 96.4999 92.2513 96.5093 91.6349C96.5003 91.1024 96.6084 90.5744 96.8259 90.0882C97.038 89.6465 97.3513 89.2611 97.7406 88.9634C98.1936 88.6345 98.6991 88.3848 99.2358 88.2252C99.8146 88.0596 100.402 87.9247 100.995 87.8209C101.646 87.7155 102.332 87.5924 103.053 87.5046L105.181 87.2058L104.372 87.6452C104.445 86.9275 104.237 86.2095 103.792 85.6415C103.517 85.3935 103.194 85.2059 102.842 85.0908C102.491 84.9758 102.119 84.9358 101.751 84.9736C101.173 84.9673 100.602 85.1127 100.098 85.3955C99.8363 85.5471 99.6082 85.7497 99.4269 85.9912C99.2456 86.2328 99.1148 86.5084 99.0423 86.8015L96.8963 86.1864C97.1524 85.2358 97.7305 84.4035 98.5322 83.8312C99.4769 83.2018 100.6 82.8936 101.734 82.9524C102.658 82.9313 103.577 83.1112 104.425 83.4797C105.201 83.795 105.829 84.3913 106.184 85.1494C106.365 85.5248 106.478 85.9295 106.518 86.3446C106.546 86.7952 106.546 87.2472 106.518 87.6979V94.7282H104.548V92.1797L104.917 92.5137C104.526 93.3207 103.917 94.0025 103.158 94.4822C102.32 94.9437 101.371 95.1685 100.414 95.1325ZM100.819 93.3749C101.383 93.3923 101.943 93.278 102.455 93.041C102.878 92.8381 103.25 92.544 103.545 92.1797C103.806 91.8616 103.992 91.4893 104.091 91.09C104.221 90.7167 104.292 90.3253 104.302 89.93C104.302 89.5082 104.302 89.1567 104.302 88.9106L105.023 89.1743L103.088 89.4731C102.525 89.4731 101.997 89.6312 101.522 89.7191C101.106 89.7898 100.694 89.8897 100.291 90.0179C100.004 90.1129 99.7269 90.2365 99.4645 90.387C99.2439 90.5313 99.0529 90.7162 98.9016 90.9318C98.7562 91.1696 98.6829 91.4443 98.6905 91.7228C98.6843 92.0145 98.7638 92.3017 98.9191 92.5488C99.0773 92.8168 99.3179 93.0264 99.6052 93.1464C99.9909 93.2968 100.407 93.3511 100.819 93.3046V93.3749Z" fill="#FF6633"/>
        <path d="M109.914 94.818V83.2706H114.998C115.331 83.2444 115.667 83.2444 116 83.2706C116.303 83.3001 116.603 83.353 116.897 83.4288C117.485 83.5966 118.006 83.9401 118.393 84.413C118.827 84.9678 119.045 85.6607 119.008 86.364C119.02 86.7611 118.948 87.1563 118.797 87.524C118.661 87.8357 118.464 88.1167 118.217 88.35L117.847 88.6137C117.725 88.6867 117.595 88.7456 117.46 88.7894C117.732 88.841 117.993 88.9359 118.234 89.0706C118.631 89.2774 118.965 89.587 119.202 89.967C119.484 90.4316 119.619 90.9709 119.589 91.5137C119.609 92.2068 119.413 92.8889 119.026 93.4646C118.632 93.9958 118.066 94.3746 117.425 94.5367C117.128 94.6279 116.821 94.681 116.51 94.6949H115.49L109.914 94.818ZM112.183 88.1567H114.699H115.314C115.507 88.1351 115.697 88.0878 115.877 88.0161C116.143 87.9259 116.363 87.7371 116.493 87.4888C116.633 87.2297 116.705 86.9396 116.704 86.6452C116.703 86.3432 116.624 86.0466 116.475 85.784C116.327 85.5267 116.089 85.3328 115.807 85.2391C115.573 85.1667 115.33 85.1254 115.086 85.1161H112.183V88.1567ZM112.183 93.0428H115.349C115.519 93.059 115.69 93.059 115.86 93.0428L116.387 92.9198C116.534 92.8823 116.67 92.8144 116.788 92.7205C116.907 92.6266 117.004 92.5088 117.073 92.3749C117.213 92.1158 117.286 91.8257 117.284 91.5313C117.304 91.1778 117.184 90.8307 116.95 90.5646C116.743 90.3186 116.457 90.1511 116.141 90.09C115.989 90.0322 115.828 90.0024 115.666 90.0022H112.148L112.183 93.0428Z" fill="#FF6633"/>
        <path d="M132.079 97.0832V94.8159H122.316V83.2686H124.55V92.7068H129.827V83.2686H132.079V92.7947H134.33V97.0832H132.079Z" fill="#FF6633"/>
        <path d="M141.629 95.1323C140.575 95.1523 139.535 94.8849 138.621 94.3589C137.78 93.8357 137.103 93.0866 136.669 92.1971C136.197 91.2102 135.962 90.127 135.983 89.0335C135.955 87.9317 136.197 86.8398 136.686 85.8522C137.119 84.9649 137.804 84.225 138.656 83.7255C139.571 83.2177 140.6 82.9512 141.647 82.9512C142.693 82.9512 143.722 83.2177 144.637 83.7255C145.484 84.2462 146.167 84.9956 146.607 85.8874C147.082 86.8668 147.318 87.9452 147.293 89.0335C147.316 90.1329 147.082 91.2225 146.607 92.2147C146.16 93.1023 145.471 93.8456 144.619 94.3589C143.713 94.8877 142.678 95.1554 141.629 95.1323ZM141.629 93.0232C142.102 93.0563 142.575 92.9726 143.008 92.7795C143.44 92.5864 143.819 92.2898 144.109 91.9159C144.681 91.0664 144.964 90.056 144.918 89.0335C144.978 88.0068 144.686 86.9904 144.092 86.151C143.803 85.7806 143.427 85.4872 142.997 85.2971C142.568 85.107 142.098 85.026 141.629 85.0613C140.975 85.0318 140.328 85.2102 139.782 85.571C139.292 85.9145 138.918 86.3987 138.709 86.9595C138.457 87.6211 138.337 88.3258 138.357 89.0335C138.298 90.0656 138.589 91.0875 139.184 91.9335C139.472 92.3002 139.845 92.5911 140.271 92.781C140.697 92.9709 141.163 93.054 141.629 93.0232Z" fill="#FF6633"/>
        <path d="M150.23 94.818V83.2706H155.314C155.648 83.2444 155.983 83.2444 156.317 83.2706C156.619 83.3001 156.919 83.353 157.214 83.4288C157.801 83.5966 158.323 83.9401 158.709 84.413C159.143 84.9678 159.362 85.6607 159.325 86.364C159.336 86.7611 159.264 87.1563 159.113 87.524C158.978 87.8357 158.78 88.1167 158.533 88.35L158.164 88.6137C158.042 88.6867 157.912 88.7456 157.777 88.7894C158.048 88.841 158.309 88.9359 158.551 89.0706C158.948 89.2774 159.282 89.587 159.518 89.967C159.8 90.4316 159.935 90.9709 159.905 91.5137C159.926 92.2068 159.729 92.8889 159.342 93.4646C158.948 93.9958 158.383 94.3746 157.741 94.5367C157.444 94.6279 157.137 94.681 156.827 94.6949H155.807L150.23 94.818ZM152.5 88.1567H154.945H155.56C155.753 88.1351 155.943 88.0878 156.123 88.0161C156.389 87.9259 156.609 87.7371 156.739 87.4888C156.879 87.2297 156.951 86.9396 156.95 86.6452C156.958 86.3421 156.878 86.0432 156.721 85.784C156.573 85.5267 156.335 85.3328 156.053 85.2391C155.819 85.1667 155.576 85.1254 155.332 85.1161H152.429L152.5 88.1567ZM152.5 93.0428H155.666C155.841 93.0591 156.018 93.0591 156.193 93.0428L156.704 92.9198C156.85 92.8823 156.987 92.8144 157.105 92.7205C157.223 92.6266 157.32 92.5088 157.39 92.3749C157.53 92.1158 157.602 91.8257 157.601 91.5313C157.62 91.1778 157.5 90.8307 157.266 90.5646C157.059 90.3186 156.773 90.1511 156.457 90.09C156.311 90.0339 156.156 90.0041 156 90.0022H152.482L152.5 93.0428Z" fill="#FF6633"/>
        <path d="M38.0312 51.1035V23.5236H41.0637V44.6002L94.0494 0.0673828V50.9114H91.017V6.57068L38.0312 51.1035Z" fill="#FF6633"/>
        <path d="M1.18457 50.9102V23.5225H12.3231V26.4785H4.14302V33.8686H7.10146C8.24878 33.8502 9.3884 34.0589 10.4547 34.4825C11.5209 34.9061 12.4928 35.5364 13.3142 36.3369C14.1487 37.1014 14.8111 38.0343 15.2574 39.074C15.7037 40.1136 15.9237 41.2362 15.9028 42.3673C15.9242 43.5 15.7074 44.6246 15.2666 45.6684C14.8258 46.7123 14.1708 47.6521 13.3438 48.4271C12.5196 49.2333 11.5434 49.8679 10.4718 50.2941C9.40024 50.7204 8.25465 50.9298 7.10146 50.9102H1.18457ZM7.10146 36.8247H4.14302V47.969H7.10146C8.64593 48.0036 10.141 47.4242 11.2581 46.3579C11.7951 45.8471 12.2227 45.2326 12.5149 44.5517C12.807 43.8708 12.9577 43.1377 12.9577 42.3968C12.9577 41.656 12.807 40.9228 12.5149 40.2419C12.2227 39.561 11.7951 38.9465 11.2581 38.4357C10.7068 37.9051 10.0554 37.4894 9.34182 37.2128C8.62819 36.9362 7.86662 36.8042 7.10146 36.8247Z" fill="#FF6633"/>
        <path d="M15.8584 50.9102L31.3607 23.5225H33.4316V50.9102H30.4731V31.0013L19.231 50.9102H15.8584Z" fill="#FF6633"/>
        <path d="M104.272 37.2082V34.2521H106.106C107.233 34.2797 108.327 33.8781 109.168 33.1288C109.571 32.7834 109.893 32.3546 110.113 31.8722C110.332 31.3898 110.444 30.8654 110.441 30.3354C110.451 29.7992 110.348 29.2669 110.139 28.7729C109.93 28.279 109.619 27.8344 109.228 27.468C108.648 26.8983 107.913 26.5129 107.114 26.3605C106.316 26.2081 105.49 26.2956 104.742 26.612C103.993 26.9283 103.355 27.4592 102.908 28.1377C102.461 28.8162 102.225 29.6118 102.231 30.4241H99.2724C99.2724 29.5002 99.4545 28.5853 99.8084 27.7317C100.162 26.8782 100.681 26.1026 101.335 25.4493C101.989 24.796 102.765 24.2778 103.619 23.9242C104.473 23.5706 105.389 23.3887 106.314 23.3887C107.238 23.3887 108.154 23.5706 109.008 23.9242C109.862 24.2778 110.639 24.796 111.292 25.4493C111.946 26.1026 112.465 26.8782 112.819 27.7317C113.172 28.5853 113.355 29.5002 113.355 30.4241C113.364 31.4058 113.148 32.3766 112.725 33.2627C112.302 34.1488 111.683 34.927 110.914 35.538C112.149 36.2714 113.169 37.3163 113.872 38.568C114.591 39.8123 114.963 41.2257 114.952 42.6621C114.952 44.9121 114.058 47.07 112.465 48.6611C110.873 50.2521 108.713 51.1459 106.461 51.1459C104.21 51.1459 102.05 50.2521 100.458 48.6611C98.8653 47.07 97.9707 44.9121 97.9707 42.6621H100.929C100.915 43.3983 101.053 44.1296 101.333 44.8108C101.612 45.4921 102.029 46.1088 102.556 46.6232C103.063 47.1534 103.674 47.573 104.352 47.8554C105.029 48.1377 105.757 48.2768 106.491 48.2638C107.966 48.2638 109.381 47.6783 110.424 46.6361C111.467 45.5939 112.053 44.1803 112.053 42.7064C112.036 41.3171 111.493 39.9859 110.532 38.9817C109.571 37.9776 108.264 37.3754 106.876 37.2969L104.272 37.2082Z" fill="#FF6633"/>
        <path d="M119.02 50.9102V23.5225H121.978V50.9102H119.02ZM128.812 23.5225H132.051L125.602 36.8247L132.436 50.9398H129.197L122.348 36.8247L128.812 23.5225Z" fill="#FF6633"/>
        <path d="M133.205 37.2108C133.323 33.6064 134.839 30.189 137.432 27.6809C140.025 25.1728 143.493 23.7705 147.102 23.7705C150.712 23.7705 154.179 25.1728 156.773 27.6809C159.366 30.189 160.882 33.6064 161 37.2108C160.882 40.8152 159.366 44.2326 156.773 46.7408C154.179 49.2489 150.712 50.6511 147.102 50.6511C143.493 50.6511 140.025 49.2489 137.432 46.7408C134.839 44.2326 133.323 40.8152 133.205 37.2108ZM139.373 29.4808C138.352 30.4958 137.541 31.7026 136.988 33.0317C136.435 34.3608 136.15 35.7861 136.15 37.2256C136.15 38.6651 136.435 40.0904 136.988 41.4195C137.541 42.7486 138.352 43.9554 139.373 44.9704C140.908 46.4922 142.858 47.5266 144.98 47.9435C147.101 48.3603 149.298 48.1411 151.295 47.3132C153.292 46.4854 155 45.086 156.203 43.2911C157.406 41.4961 158.05 39.3858 158.056 37.2256C158.057 35.0622 157.417 32.9471 156.215 31.1476C155.013 29.3481 153.304 27.9451 151.305 27.1161C149.305 26.287 147.104 26.0692 144.98 26.4901C142.856 26.911 140.905 27.9518 139.373 29.4808Z" fill="#FF6633"/>
      </svg>  
      </div>
    )
}
import useAnalyticsEventTracker, { googleAnalyticsAction, googleAnalyticsCategory, googleAnalyticsLabel } from '../GoogleAnalytics';
import React, { useEffect, useState } from 'react';

import { ButtonThemed } from '../base/ButtonThemed';
import { PreloaderIcon } from '../icons/Preloader.icon';
import cn from 'classnames';
import css from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export const Preloader = () => {
  const [longTime, setLongTime] = useState(false);
  const { t } = useTranslation();
  const pathname = window.location.href;
  const isRedirect = pathname.toLowerCase().includes('/ss/') || pathname.toLowerCase().includes('/stores/');
  setTimeout(() => {
    setLongTime(true);
  }, 10000);
  const handleClick = () => {
    window.location.reload()
  }
  useEffect(() => {
    useAnalyticsEventTracker({ category: googleAnalyticsCategory.StartPwa, action: googleAnalyticsAction.LoadScreen, label: googleAnalyticsLabel.PreloaderDisplay })
  })
  return (
      isRedirect ? <></>
        : <div className={cn(css['loaderContainer'])}>
            <div className={cn(css['loader'])}>
              <PreloaderIcon />
              {!isRedirect && longTime ? (
                <div className={cn(css['dontShowInternetIssuesContainer'], longTime ? css['internetIssuesContainer'] : '')}>
                  <p className={cn(css['internetIssuesText'])}>
                    {t('common.preLoad.loadingTakeTooMuchTime')}
                  </p>
                  <p className={cn(css['internetIssuesDescribe'])}>
                    {t('common.preLoad.internetProblem')}
                  </p>
                  <ButtonThemed
                    type='active'
                    text={t('common.buttons.refreshPage')}
                    onClick={handleClick}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
  );
};

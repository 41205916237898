import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector } from 'react-redux';

import { AnyAction } from 'redux';
import { AppReduxState } from '..';
import { Nullable } from 'core/types';

/**
 * Redux action
 * @template TPayload Type of payload
 */
export interface IReduxAction<TPayload> {
    /**
     * Redux action type
     */
    type: string;

    /**
     * Redux action payload
     */
    payload?: ReduxPayload<TPayload>;
}

/**
 * Represents state of action type
 */
export enum ReduxActionStateType {
    Execute = 'execute',
    Success = 'success',
    Failed = 'failed'
}


export interface IFailedActionPayload {
    error: Error;
}

export type ReduxPayload<TSuccessPayload> = TSuccessPayload | IFailedActionPayload;

type ReduxActionStateTypeKeys = `${ReduxActionStateType}`;

export type TripleReduxActionType = {
    [key in ReduxActionStateTypeKeys]: string;
}

/**
 * Returns execute/success/failed action type names
 * @param actionTypeName action type name
 */
export function createReduxActionTypes(actionTypeName: string): TripleReduxActionType {
    return {
        execute: `EXECUTE_${(actionTypeName ?? '').toUpperCase()}_ACTION`,
        success: `SUCCESS_${(actionTypeName ?? '').toUpperCase()}_ACTION`,
        failed: `FAILED_${(actionTypeName ?? '').toUpperCase()}_ACTION`,
    }
}


/**
 * Общий тип для Thunk функций приложения
 * @template TActions - какой тип action-а выполнится в редюсере
 * @template TPayload - тип данных для типа action-а для отправки в redux хранилище
 */
export type AppThunk<TPayload, TExtra = {}> = ThunkAction<void, AppReduxState, TExtra, IReduxAction<TPayload>>


/**
 * Дополнительное состояние для состояния редюсера
 */
export interface IReducerStateExecution {
    /**
     * Находится в состоянии выполнения
     */
    isInProgress: boolean;
    isLoading: boolean;
    /**
     * Выполнение закончилось с ошибкой
     */
    error: Nullable<Error> | string;
}
export type TypedDispatch = ThunkDispatch<AppReduxState, any, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<AppReduxState> = useReduxSelector

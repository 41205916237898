import { IsBlizko, IsFoodSpot } from 'core/availableFunctional';

import { blizkoPalette } from './Palettes/blizko';
import { createTheme } from '@mui/material/styles';
import { foodSpotPalette } from './Palettes/foodSpot';
import { izipointPalette } from './Palettes/izipoint';

const theme = IsBlizko ? blizkoPalette : IsFoodSpot ? foodSpotPalette : izipointPalette

export const Colors = {
    imgTitle: theme.imgTitle,
    cal: theme.cal,
    prot: theme.prot,
    fat: theme.fat,
    carbs: theme.carbs,
    defaultColor: theme.defaultColor,
    dark: theme.dark,
    backGround: theme.backGround,
    secondary: theme.secondary,
    mainColor: theme.mainColor,
    iconsColor: theme.iconsColor,
    textColor: theme.textColor,
    accentColor: theme.accentColor,
}
export const generalTheme = createTheme({
    typography: {
        body1: {
            fontSize: '20px',
            fontFamily: ['Proxima Nova Black', 'Arial', 'Helvetica', 'sans-serif'].join(','),
            lineHeight: 1.4,
            color: Colors.secondary,
        },
    },
    palette: {
        primary: {
            main: Colors.cal,
        },
    },
});

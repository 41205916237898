import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import { useTypedSelector } from '../../../../store/common';

interface MyProps {

}

export const ContainerThemed = (props: React.PropsWithChildren<MyProps>) => {
    const ActivePopUp = useTypedSelector(state => state.PurchaseState.ActivePopUp);
    return (
      <div className={ cn(ActivePopUp.isAuthInPurchase ? css['authInPurchaseBlock'] : css['container']) }>
          { props.children }
      </div>
    )
}
export const blizkoPalette = {
    imgTitle: '#F34300',
    cal: '#B3E085',
    prot: '#A27DE8',
    fat: '#E46799',
    carbs: '#E49967',
    defaultColor: '#ffffff',
    dark: '#333333',
    backGround: '#191919',
    secondary: '#CCCCCC',
    mainColor: '#888888',
    iconsColor: '#FFF',
    textColor: '#FFF',
    accentColor: '#009F48',
}
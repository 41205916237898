export const foodSpotPalette = {
    imgTitle: '#F3AC1C',
    cal: '#B3E085',
    prot: '#A27DE8',
    fat: '#E46799',
    carbs: '#E49967',
    defaultColor: '#ffffff',
    dark: '#3E612A',
    backGround: '#FAF2E2',
    secondary: '#8DC868',
    mainColor: '#6EB547',
    iconsColor: '#4A4A4A',
    textColor: '#4A4A4A',
    accentColor: '#F3AC1C',
}
import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

export interface MyProps {
    type: 'active' | 'inactive' | 'secondary' | 'pay' | 'custom' | 'edit';
    text: string;
    onClick?: () => void;
    isDisabled?: boolean;
    className?: string;
}

export const ButtonThemed = (props: React.PropsWithChildren<MyProps>) => {
    const defaultClassName = (type: string): keyof typeof css => {
        switch (type) {
            case 'active':
                return 'btnActive';
            case 'inactive':
                return 'btnInactive';
            case 'secondary':
                return 'btnSecondary';
            case 'pay':
                return 'btnPay';
            case 'custom':
                return 'btnCustom';
            case 'edit':
                return 'btnProfileEdit';
            default:
                return 'btnActive';
        }
    };
    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    }
    const className = defaultClassName(props.type);
    return (
      <button
        disabled={props.isDisabled}
        onClick={handleClick}
        className={cn(css[className], [props.className])}
      >
          {props.children}
          {props.text && <p>{props.text}</p>}
      </button>
    );
};

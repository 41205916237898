import { Colors } from 'app/views/pages/WebPurchase/Themes/GlobalTheme/GeneralTheme';
import { FoodSpotIcon } from './FoodSpot.icon';
import ReactLoading from 'react-loading';
import cn from 'classnames';
import css from '../../../Preloader/styles.module.scss';
import React from 'react';

export const FoodSpotPreloader = () => {
  return (
    <>
      <div className={cn(css['foodSpotLogo'])}>
        <FoodSpotIcon width={150} height={150} />
      </div>
      <div className={cn(css['svgContainer'])}>
        <ReactLoading
          color={Colors.defaultColor}
          type='bubbles'
          height='45vw'
          width='45vw'
        />
      </div>
    </>
  );
};
import { Colors } from '../../pages/WebPurchase/Themes/GlobalTheme/GeneralTheme';
import React from 'react';
import { ScanHelpIconType } from './ScanHelp.Icon';

export const CrossIcon = (props:ScanHelpIconType) => {
  return (
    <>
    <svg width={props.width} height={props.height} fill="none" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <path d="m99.329 199.54c54.858 0 99.329-44.67 99.329-99.773s-44.471-99.772-99.329-99.772c-54.858 0-99.329 44.67-99.329 99.772s44.471 99.773 99.329 99.773z" fill={Colors.imgTitle} opacity=".02"/>
      <path d="m99.277 159.26c32.738 0 59.277-26.658 59.277-59.542 0-32.884-26.539-59.542-59.277-59.542-32.738 0-59.277 26.658-59.277 59.542 0 32.884 26.539 59.542 59.277 59.542z" fill={Colors.imgTitle} opacity=".08"/>
      <path d="m99.132 184.86c46.895 0 84.911-38.185 84.911-85.289 0-47.104-38.016-85.289-84.911-85.289s-84.91 38.185-84.91 85.289c0 47.104 38.016 85.289 84.91 85.289z" fill={Colors.imgTitle} opacity=".04"/>
      <path d="m70.283 129.72 58.86-59.183" stroke={Colors.imgTitle} strokeWidth="12"/>
      <path d="m69.333 72.445 56.962 57.274" stroke={Colors.imgTitle} strokeWidth="12"/>
    </svg>
    </>
  );
};


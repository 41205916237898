import ReactGA from 'react-ga4';

interface IProps {
  category: googleAnalyticsCategory;
  action: googleAnalyticsAction;
  label: googleAnalyticsLabel | string;
}
interface ITimingProps {
  category: googleAnalyticsCategory;
  variable: googleAnalyticsApiCalls | googleAnalyticsPageLoad | '' | string;
  startTime: number;
  date?: string;
}
const useAnalyticsEventTracker = (props: IProps) => {
  ReactGA.event({
    category: props.category,
    action: props.action,
    label: props.label,
  });
};

export const useAnalyticsTimingTracker = (props: ITimingProps) => {
  const endTime = performance.now();
  const duration = Number(Math.floor((endTime - props.startTime)));
  ReactGA.event({
    category: props.category,
    action: props.variable,
    label: props.date ? `${props.date} | ${duration.toString()}` : duration.toString(),
  });
}

export enum googleAnalyticsCategory {
  StartPwa = 'Start pwa',
  Profile = 'Profile',
  ProfileErrors = 'Profile Errors',
  History = 'History',
  HistoryErrors = 'History Errors',
  Purchase = 'Purchase',
  PurchaseErrors = 'Purchase Errors',
  Catalog = 'Catalog',
  CatalogErrors = 'Catalog Errors',
  Support = 'Support',
  SupportErrors = 'Support Errors',
}
export enum googleAnalyticsAction {
  // Base
  CrashReport = 'Crash report',
  LoadScreen = 'Load screen',
  ButtonClick = 'Button click',
  Errors = 'Errors',
  CameraErrors = 'Camera Errors',
  ApiErrors = 'Api Call Errors',
  // Profile
  Auth = 'Auth',
  GoogleAuth = 'Google Authorization',
  PaymentMethod = 'Payment Method',
  Language = 'Language',
  AddCard = 'Add Card',
  DeleteAccount = 'Delete Account',
  ValidateEmail = 'Validate Email',
  ChangeProfileData = 'Change Info',
  SignOut = 'Sign Out',
  RegisterNewCard = 'Register new card',
  ClearPhoneNumber = 'Clear Phone Number',
  // Purchase
  UserType = 'User type',
  PurchaseType = 'Purchase type',
  AddProducts = 'Add products',
  RemoveProduct = 'Remove product',
  Payment = 'Payment',
  SuccessfulPurchase = 'Successful purchase',
  CanceledPurchase = 'Canceled purchase',
  AuthInPurchase = 'Auth in purchase',
  OpenAnotherDoor = 'Open another door',
  GetSbpLinkToPay = 'Get sbp link to pay',
  RegisterUser = 'Register User',
  GetPromoBonuses = 'Get promo bonuses',
  GetUserProfile = 'Get user profile',
  GetUserCards = 'Get user cards',
  DeleteCard = 'Delete Card',
  PayCheck = 'Payment Completition',
  Completition3ds = '3ds Completition',
  StripeError = 'Stripe Errors',
  SBP = 'SBP',
  Unknown = 'Unknown errors',
  BarcodeDecode = 'Barcode decode error',
  CameraScan = 'Camera Scanned',
  // HISTORY
  PressOnIncorrectCheck = 'Press On Incorrect check button',
  // Support
  OpenMessengers = 'Open messengers',
  ErrorBoundary = 'Error boundary',
}

export enum googleAnalyticsLabel {
  // Base
  Click = 'Click',
  Cancel = 'Cancel',
  TabLoad = 'Tab load',
  GetUnpaidCheck = 'Get Unpaid Check',
  PreloaderDisplay = 'Preloader',
  // PROFILE
  ThroughGoogle = 'Through Google',
  ThroughApple = 'Through Apple',
  ThroughPhone = 'Through Phone',
  GetProfileData = 'Get profile data',
  SignOut = 'Sign out',
  IncorrectCheck = 'Incorrect check', // Payment Method
  AddCard = 'Add Card',
  DeleteCard = 'Delete card',
  SBPAsDefaultAuthed = 'SBP as default authed',
  SBPAsDefaultUnauthed = 'SBP as default unauthed',
  YaPayAsdefault = 'YaPay as default',
  YaPay = 'YaPay',
  GooglePay = 'Google pay',
  ApplePay = 'Apple pay', // Choose Language
  SetUserLanguage = 'Set User Language',
  English = 'English',
  Russian = 'Russian',
  DeleteProfile = 'Delete Profile',
  ClearPurchase = 'Clear Purchase',
  GetUserInfo = 'Get User Info',
  UpdateUserInfo = 'Update User Info',
  ClearPhoneNumber = 'ClearPhoneNumber',
  // History
  PurchaseHistory = 'Purchase history',
  Check = 'Check',
  // Purchase
  PosInitiation = 'Pos initiation',
  PosInitiationByLink = 'Initiate Micro-Market by Link',
  CheckOpen = 'Check open',
  UserAuthorized = 'User authorized',
  UserUnauthorized = 'User unauthorized',
  WebPurchase = 'Web purchase',
  InitialPurchase = 'Initial purchase',
  QRCode = 'QR-Code',
  Barcode = 'Barcode',
  AddedManually = 'Added manually',
  ProductRemoved = 'Product removed',
  WithSavingCard = 'With saving card',
  PayWithSbp = 'Pay with SBP',
  WithoutSavingCard = 'Without saving card',
  AddYaPayCardInModalView = 'Add YaPay card in modal view',
  CancelInGettingSBPstatus = 'Cancel in getting SBP status',
  OpenSuccessfulPurchasewindow = 'Open successful purchase window',
  CancelPurchase = 'Cancel purchase',
  CancelModalAuthInPurchase = 'Cancel modal auth in purchase',
  AuthorizedBefore30sec = 'Authorized before 30sec',
  AuthorizedAfter30sec = 'Authorized after 30sec',
  DownloadCPWA = "Download CPWA",
  AddProduct = 'Add product',
  DeleteProduct = 'Delete product',
  Card = 'Pay by card',
  SavedCard = 'Pay by saved card',
  SBPAuthed = 'Оплата СБП авториз',
  SBPUnauthed = 'Оплата СБП неавториз',
  SBPPressAuthed = 'Нажатие на сбп авторизированным',
  SBPPressUnauthed = 'Нажатие на сбп неавторизированным',
  SBPPressAuthedWebPurchase = 'Нажатие на сбп авторизированным',
  SBPPressUnauthedWebPurchase = 'Нажатие на сбп неавторизированным',
  SBPPressWebPurchase = 'Нажатие на сбп в вебпокупке',
  PaySBPAsDefaultMethodAuthed = 'Вызо.в оплаты сбп как способом оплаты по умолчанию авториз',
  PaySBPAsDefaultMethodUnauthed = 'Вызов оплаты сбп как способом оплаты по умолчанию неавториз',
  PaySBPAsDefaultMethodInWebPurchaseAuthed = 'Вызов оплата сбп как способом оплаты по умолчанию в веб покупке автор.',
  PaySBPAsDefaultMethodInWebPurchaseUnauthed = 'Вызов оплата сбп как способом оплаты по умолчанию в веб покупке неавтор.',
  NotListed = 'Not listed',
  CodeCorpScan = 'Code Corp',
  // Catalog
  CatalogView = 'Catalog view',
  ProductView = 'Product view',
  // Support
  WhatsAppOpen = 'WhatsApp open',
  TelegramOpen = 'Telegram open',
  FBMessengerOpen = 'FBMessenger open',
  Error = 'Error',
  // API CALLS
  GetAccountStatus = 'GetAccountStatus',
  ResendCodeChangedPhoneNumber = 'resendCodeChangedPhoneNumber',
  CodeRegisterPhoneNumber = 'CodeRegisterPhoneNumber',
  InitiatePurchase = 'InitiatePurchase',
  OpenAnotherDoor = 'Open another door',
  InitiatePurchaseByCamera = 'InitiatePurchase by phone camera',
  VerifyChangedPhoneNumber = 'VerifyChangedPhoneNumber',
  BonusPromotionByPosOperation = 'BonusPromotionByPosOperation',
  GetUserProfileInfo = 'GetUserProfileInfo',
  GetCatalog = 'GetCatalog',
  GetCatalogInfoById = 'GetCatalogInfoById',
  GetBrandInfo = 'GetBrandInfo',
  GetBonusPromotionOfBrand = 'GetBonusPromotionOfBrand',
  GetCheckById = 'GetCheckById',
  GetChecksWithPagination = 'GetChecksWithPagination',
  GetSupportContacts = 'GetSupportContacts',
  Completition3ds = '3dsCompletition PayCheck',
  GetAllCards = 'GetAllUserCards',
  GetActiveCard = 'GetActiveCard',
  SetActiveCard = 'SetActiveCard',
  IsApplePayAvailable = 'IsApplePayAvailable',
  GooglePayCloudError = 'GooglePayCloudError',
  GetGatewayByCountryIso = 'GetGatewayByCountryIsoAsync',
  SetupStripeIntent = 'SetupStripeIntent',
  StripePreRegistration = 'StripePreRegistration',
  RegisterNewStripeCard = 'TryRegisterNewStripeCard',
  RegisterNewCloudCard = 'TryRegisterNewCloudCard',
  RegisterCardCryptogramCloud = 'RegisterCardCryptogramCloud',
  CantGetDeviceCamera = 'CantGetDeviceCamera',
  GetAuthToken = 'GetAuthToken',
  UpdateAuthToken = 'UpdateAuthToken',
  PayCheck = 'PayCheck',
  GetSbpLinkToPay = 'GetSbpLinkToPay',

  // Crashes
  DoubleAuthRefreshTokens = 'Присутствуют токены для авторизации для авторизованного и неавторизованного пользователя'
}

export enum googleAnalyticsApiCalls {
  // PURCHASE
  GetAccountInfo = 'GetAccountInfo',
  GetToken = 'GetToken',
  GetTokenWithDate = 'GetToken with date',
  GetRefreshToken = 'GetRefreshToken',
  RegisterUser = 'RegisterUser',
  GetLabeledGoodsInfo = 'GetLabeledGoodsInfo',
  GetLabeledGoodsInfoWithDate = 'GetLabeledGoodsInfo with date',
  InitiatePurchase = 'InitiatePurchase',
  InitiatePurchaseWithDate = 'InitiatePurchase With Date',
  AddCheckItem = 'AddCheckItem',
  AddCheckItemWithDate = 'AddCheckItem with date',
  DeleteCheckItem = 'DeleteCheckItem',
  GetCheckById = 'GetCheckById',
  GetUnpaidCheck = 'GetUnpaidCheck',
  AddCustomQuantity = 'Add product, quantity: ',
  RemoveCustomQuantity = 'Remove product, quantity: ',
  GetUnpaidCheckWithDate = 'GetUnpaidCheck with date',
  CancelPurchase = 'CancelPurchase',
  CancelPurchaseWithDate = 'CancelPurchase with date',
  OpenAnotherDoor = 'OpenAnotherDoor',
  GetMerchantId = 'GetMerchantId',
  GetMerchantIdWithDate = 'GetMerchantId with date',
  ValidatePwaPayment = 'ValidatePwaPayment',
  PayCheck = 'PayCheck',
  PayCheckWithDate = 'PayCheck with date',
  GetSbpLink = 'GetSbpLink',
  GetSbpLinkWithDate = 'GetSbpLink with date',
  RequestKind = 'RequestKind',
  GetPaymentStatus = 'GetPaymentStatus',
  StartApplePaySession = 'StartApplePaySession',
  RequestAppLinks = 'RequestAppLinks',
  FromScanToRequest = "FromScanToRequest",
  // PROFILE
  RegisterGoogleUser = 'RegisterGoogleUser',
  RegisterAppleUser = 'RegisterAppleUser',
  RegisterPhoneNumberUser = 'RegisterPhoneNumberUser',
  ChangePhoneNumberUser = 'ChangePhoneNumberUser',
  VerifyPhoneNumberUser = 'VerifyPhoneNumberUser',
  VerifyChangedPhoneNumberUser = 'VerifyChangedPhoneNumberUser',
  GetGatewayByCountryIso = 'GetGatewayByCountryIso',
  GetPreRegistration = 'GetPreRegistration',
  TryRegisterNewCard = 'TryRegisterNewCard',
  GetAllCard = 'GetAllCard',
  DeleteCard = 'DeleteCard',
  SetActiveCard = 'SetActiveCard',
  GetActiveCard = 'GetActiveCard',
  GetPublicId = 'GetPublicId',
  GetUser = 'GetUser',
  GetUserWithDate = 'GetUser with date',
  SetUserInfo = 'SetUserInfo',
  SetUserLang = 'SetUserLang',
  DeleteAccount = 'DeleteAccount',
  RestoreAccount = 'RestoreAccount',
  StatusAccount = 'StatusAccount',
  UserBonusesRecords = 'UserBonusesRecords',
  BrandBonusesSVG = 'BrandBonusesSVG',
  BonusBalance = 'BonusBalance',
  BonusBalanceWithDate = 'BonusBalance with date',
  BonusPromotions = 'BonusPromotions',
  BonusPromotionsOfBrand = 'BonusPromotionsOfBrand',
  BonusPromotionsOfBrandWithDate = 'BonusPromotionsOfBrand with date',
  BonusPromotionsId = 'BonusPromotionsId',
  BonusPromotionsOfPos = 'BonusPromotionsOfPos',
  ClearPhoneNumber = 'ClearPhoneNumber',
  // CATALOG
  GetCatalogs = 'GetCatalogs',
  GetCatalogInfoById = 'GetCatalogInfoById',
  GetBrandInfo = 'GetBrandInfo',
  // HISTORY
  GetChecks = 'GetChecks',
  UpdateCorrectnessStatus = 'UpdateCorrectnessStatus',
  // SUPPORT
  GetSupportContacts = 'GetSupportContacts',
}

export enum googleAnalyticsPageLoad {
  PurchaseTab = 'Purchase Tab',
  CameraLoad = 'Camera Load',
  SuccessPurchaseTab = 'Success Purchase Tab',
  CatalogInfo = 'Catalog Info',
  CatalogList = 'Catalog List',
  ProductInfo = 'Product Info',
  UserIsBlocked = 'UserIsBlocked',
  CodeVerificationPage = 'CodeVerification Tab',
  AuthPage = 'Auth Page',
  ProfileTab = 'Profile Tab',
  PurchaseHistoryTab = 'Purchase History Tab',
  SupportTab = 'Support Tab',
}

export default useAnalyticsEventTracker;

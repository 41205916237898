// import useAnalyticsEventTracker, { googleAnalyticsAction, googleAnalyticsCategory, googleAnalyticsLabel } from '../GoogleAnalytics';

import React, { Component, ErrorInfo, ReactNode } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import useAnalyticsEventTracker, { googleAnalyticsAction, googleAnalyticsCategory } from '../GoogleAnalytics';

import { Box } from '@mui/system';
import { ButtonThemed } from '../base/ButtonThemed';
import { ContainerThemed } from '../base/ContainerThemed';
import { CrossIcon } from '../icons/CrossIcon';
import SafeArea from 'app/views/components/SafeArea';
import cn from 'classnames';
import css from './styles.module.scss';

interface Props extends WithTranslation {
  children?: ReactNode;
  mainComponent?: boolean;
}

interface State {
  hasError: boolean;
}

export const ErrorBoundaryFallback = () => {
  const { t } = useTranslation()
  return (
        <ContainerThemed>
          <SafeArea>
            <CrossIcon width={230} height={230} />
            <p className={cn(css['errorHeader'])}>
              {t('common.errors.oopsSomethingWentWrong')}
            </p>
            <p
              className={cn(css['errorDescription'])}
              style={{ color: '#fff' }}
            >
              {t('common.errors.sendInfoToDeveleopers')} {t('common.errors.pleaseRefreshPage')}
            </p>
            <div className={cn(css['errorBtnContainer'])}>
              <ButtonThemed
                className={cn(css['refreshPageBtn'])}
                type='active'
                text={t('common.buttons.refreshPage')}
                onClick={() => window.location.reload() }
              />
            </div>
          </SafeArea>
        </ContainerThemed>
  )
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Обновляет стейт, чтобы следующий рендер показал fallback
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    useAnalyticsEventTracker({ category: googleAnalyticsCategory.StartPwa, action: googleAnalyticsAction.ErrorBoundary, label: `${error}, ${errorInfo}` })
    console.error('Error from boundary: ', error, errorInfo);
  }
  public render() {
    if (this.state.hasError) {
      return (
        <>
          {this.props.mainComponent ? (
            <ContainerThemed>
              <SafeArea>
                <CrossIcon width={230} height={230} />
                <p className={cn(css['errorHeader'])}>
                  {this.props.t('common.errors.oopsSomethingWentWrong')}
                </p>
                <p
                  className={cn(css['errorDescription'])}
                  style={{ color: '#fff' }}
                >
                  {this.props.t('common.errors.sendInfoToDeveleopers')} {this.props.t('common.errors.pleaseRefreshPage')}
                </p>
                <div className={cn(css['errorBtnContainer'])}>
                  <ButtonThemed
                    className={cn(css['refreshPageBtn'])}
                    type='active'
                    text={this.props.t('common.buttons.refreshPage')}
                    onClick={() => window.location.reload() }
                  />
                </div>
              </SafeArea>
            </ContainerThemed>
          ) : (
            <Box>
              <p className={cn(css['errorHeaderMinor'])}>
              {this.props.t('common.errors.oopsSomethingWentWrong')}
              </p>
              <p className={cn(css['errorDescriptionMinor'])}>
              {this.props.t('common.errors.sendInfoToDeveleopers')}
              </p>
              <p
                className={cn(css['refreshPageTextMinor'])}
                onClick={() => window.location.reload()}
              >
                {this.props.t('common.buttons.refreshPage')}
              </p>
            </Box>
          )}
        </>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);

export const izipointPalette = {
    imgTitle: '#EEF33E',
    cal: '#B3E085',
    prot: '#A27DE8',
    fat: '#E46799',
    carbs: '#E49967',
    defaultColor: '#ffffff',
    dark: '#4A4A4A',
    backGround: '#595959',
    secondary: '#CCCCCC',
    mainColor: '#888888',
    iconsColor: '#FFF',
    textColor: '#FFF',
    accentColor: '#EEF33E',
}
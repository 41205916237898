export const sentryIgnoreErrors = [
    // Ошибки, которые вызываются самим Sentry
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    // CAMERA
    // Ошибки связаные с библиотекой CortexWeb, которая отвечает за камеру
    "Cannot set properties of null (setting 'srcObject')",
    "null is not an object (evaluating 'se.srcObject=this.stream')",
    'Problem while creating decoder handle',
    'Non-Error promise rejection captured with value: Problem while creating decoder handle',
    'Non-Error promise rejection captured with value: Decoder has not been initialized',
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    "undefined is not an object (evaluating 'r.id')",
    "undefined is not an object (evaluating 'u.id')",
    "undefined is not an object (evaluating 'f.data')",
    "undefined is not an object (evaluating 'e.id')",
    "Cannot read properties of undefined (reading 'id')",
    "Unable to start selected Camera",
    /Unable to start selected Camera/i,
    'Permission denied',
    'Camera stopped. Please restart camera',
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    // Ошибки от библиотеки
    'Failure to initialize the lib files',
    /Failure to initialize the lib files/i,
    "undefined is not an object (evaluating 'n._CRD_Create')",
    /undefined is not an object (evaluating 'n._CRD_Create')/i,
    "Cannot read property '_CRD_Create' of undefined",
    "(evaluating 'n._CRD_Create')",
    "n._CRD_Create",
    // Ошибка возникает при открытии витрины и сигнализирует о том что акций нет
    'Произошла ошибка. 204(No Content).',
    /Произошла ошибка. 204(No Content)./i,
    'An error has occurred. 204(No Content).',
    '204(No Content)',
    /204(No Content)./i,
    // Ошибка с mqtt
    'e.off is not a function',
    // ошибка после перехода с вкладки "покупка" на другую
    'The play() request was interrupted because the media was removed from the document',
    'The play() request was interrupted by a new load request',
    'The play() request was interrupted by a call to pause()',
    // ошибка получения IP адреса
    // 'Could not get the public IP address',
    // отмена пользователем дейсвтия
    'отменено',
    //
    'JSON Parse error: Unexpected identifier "undefined"',
    '"undefined" is not valid JSON',
    //
    'Aborted(both async and sync fetching of the wasm failed)',
    //
    'processRandomSelector is not defined',
    //
    'WebAssembly is not defined',
    'AbortController is not defined',
    //
    'The operation was aborted.',
    //
    '401(Unauthorized).',
    'User can send only 5 asynchronous request(s).',
    'Failed to fetch',
    /Failed to fetch/i,
    'Load failed',
    /Load failed/i,

    "null is not an object (evaluating 'r.store')",
    "Cannot read properties of null (reading 'hide')",
    /Cannot read properties of null (reading 'hide')/i,
    "undefined is not an object (evaluating 'h.data')",
    "null is not an object (evaluating 'this.overlay.hide')",
    "Cannot read properties of null (reading 'close')",
    "SecurityError: Failed to register a ServiceWorker for scope ('https://pos.izipoint.io/') with script ('https://pos.izipoint.io/sw.js'): The script has an unsupported MIME type ('text/html').",
    /SecurityError: Failed to register a ServiceWorker for scope /i,
    "An SSL error has occurred and a secure connection to the server cannot be made.",
    /An SSL error has occurred and a secure connection to the server cannot be made./i,
    'MIME Type is not a JavaScript MIME type',
    /MIME Type is not a JavaScript MIME type/i,
  ]

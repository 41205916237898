import { IsBlizko, IsFoodSpot } from "core/availableFunctional"

/* eslint-disable no-undef */
export const setupPWA = () => {
    const manifestPlaceholder = document.querySelector('#manifest-placeholder')
    const iconPlaceholder = document.querySelector('#icon-placeholder')
    if (IsFoodSpot) {
        document.title = 'FoodSpot'
        iconPlaceholder.setAttribute('href', '/icons/foodSpot/appIcon.png')
        const myDynamicManifest = {
          "name": "FoodSpot",
          "id": "/",
          "short_name": "FoodSpot",
          "theme_color": "#000000",
          "background_color": "#4F7837",
          "display": "standalone",
          "orientation": "portrait",
          "//": "Append tracking parameters to start_url",
          "start_url": "/?utm_source=homescreen",
          "scope": ".",
          "icons": [
            {
              "src": "favicon.ico",
              "sizes": "64x64 32x32 24x24 16x16",
              "type": "image/x-icon",
              "purpose": "any maskable",
            },
            {
              "src": "logo144.png",
              "type": "image/png",
              "sizes": "144x144",
              "purpose": "any maskable",
            },
            {
              "src": "logo192.png",
              "type": "image/png",
              "sizes": "192x192",
              "purpose": "any maskable",
            },
            {
              "src": "logo512.png",
              "type": "image/png",
              "sizes": "512x512",
              "purpose": "any maskable",
            },
          ],
        }
          const stringManifest = JSON.stringify(myDynamicManifest);
          const blob = new Blob([stringManifest], { type: 'application/json' });
          const manifestURL = URL.createObjectURL(blob);
          manifestPlaceholder.setAttribute('href', manifestURL)
        }
    if (IsBlizko) {
      document.title = 'БЛИЗКО микромаркеты без продавцов'
      iconPlaceholder.setAttribute('href', '/icons/blizko/appIcon.ico')
      const myDynamicManifest = {
        "name": "Blizko",
        "id": "/",
        "short_name": "Близко",
        "theme_color": "#000000",
        "background_color": "#595959",
        "display": "standalone",
        "orientation": "portrait",
        "//": "Append tracking parameters to start_url",
        "start_url": "/?utm_source=homescreen",
        "scope": ".",
        "icons": [
          {
            "src": "favicon.ico",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon",
          "purpose": "maskable",
        },
        {
          "src": "favicon.ico",
          "sizes": "96x96",
          "type": "image/png",
          "purpose": "maskable",
        },
        {
          "src": "logo144.png",
          "type": "image/png",
          "sizes": "144x144",
          "purpose": "maskable",
        },
        {
          "src": "logo192.png",
          "type": "image/png",
          "sizes": "192x192",
          "purpose": "maskable",
        },
        {
          "src": "logo192.png",
          "type": "image/png",
          "sizes": "192x192",
          "purpose": "any",
        },
        {
          "src": "logo512.png",
          "type": "image/png",
          "sizes": "512x512",
          "purpose": "maskable",
        },
      ],
      }
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      manifestPlaceholder.setAttribute('href', manifestURL)
    }
    }